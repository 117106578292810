import React from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LandingPage.css';
import Footer from '../Footer/Footer';

function LandingPage() {
  const navigate = useNavigate();

  return (
    <Container fluid className="landing-page py-5">
      {/* Hero Section */}
      <section className="hero-section text-center mb-5">
  <h1 className="hero-title">Find Experts, Access Courses, and Unlock Opportunities</h1>
  <p className="hero-subtitle">
    Connect with industry experts, access professional courses, and discover remote work opportunities to boost your career.
  </p>
  <Button onClick={() => navigate('/auth')} className="cta-button mb-4">
    Get Started - Join the Community
  </Button>
</section>


      {/* Features Section */}
      <section className="features-section mb-5">
        <h2 className="text-center mb-4">Why Choose Us?</h2>
        <Row>
          <Col md={4} className="mb-4">
            <Card className="text-center feature-card h-100">
              <Card.Body>
                <img src="/images/feature1.png" alt="Expert Guidance" className="img-fluid mb-3" />
                <Card.Title>Expert Guidance</Card.Title>
                <Card.Text>
                  Get personalized advice from industry experts to enhance your skills and achieve your goals.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="text-center feature-card h-100">
              <Card.Body>
                <img src="/images/feature2.png" alt="Premium Courses" className="img-fluid mb-3" />
                <Card.Title>Premium Courses</Card.Title>
                <Card.Text>
                  Access a wide range of courses designed to provide you with the latest industry knowledge.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="text-center feature-card h-100">
              <Card.Body>
                <img src="/images/feature3.png" alt="Remote Work Opportunities" className="img-fluid mb-3" />
                <Card.Title>Remote Work Opportunities</Card.Title>
                <Card.Text>
                  Discover legitimate work-from-home jobs and freelance opportunities across various fields.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      {/* Sign Up/In Section */}
      <section className="signup-section text-center py-5">
        <h2>Ready to Get Started?</h2>
        <p className="mb-4">Join thousands of users who trust our platform to help them succeed.</p>
        <Button onClick={() => navigate('/auth')} className="cta-button mb-4">
          Sign Up / Login Now
        </Button>
        <img
          src="/images/signup-banner.png"
          alt="Get Started with Our Platform"
          className="img-fluid signup-banner"
          style={{ maxHeight: '300px', width: '100%', objectFit: 'cover' }}
        />
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section py-5">
        <h2 className="text-center mb-4">What Our Users Say</h2>
        <Row>
          <Col md={4} className="mb-4">
            <Card className="text-center testimonial-card h-100">
              <Card.Body>
                <img src="/images/user1.png" alt="User Testimonial" className="img-fluid rounded-circle mb-3" />
                <Card.Text>
                  "This platform has been a game-changer for my career. The expert guidance and courses have helped me
                  grow immensely!"
                </Card.Text>
                <Card.Footer>— John Doe, Marketing Specialist</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="text-center testimonial-card h-100">
              <Card.Body>
                <img src="/images/user2.png" alt="User Testimonial" className="img-fluid rounded-circle mb-3" />
                <Card.Text>
                  "The remote work opportunities I found here are unmatched. I’ve been able to work from home and still
                  pursue my passions."
                </Card.Text>
                <Card.Footer>— Jeff Smith, Freelance Designer</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="text-center testimonial-card h-100">
              <Card.Body>
                <img src="/images/user3.png" alt="User Testimonial" className="img-fluid rounded-circle mb-3" />
                <Card.Text>
                  "I love how easy it is to connect with professionals. This platform has everything I need to stay
                  ahead in my field."
                </Card.Text>
                <Card.Footer>— Alex Johnson, Software Developer</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section text-center py-5">
        <h2>Don’t Miss Out on the Opportunity to Grow</h2>
        <p className="mb-4">Sign up today and unlock access to top-tier resources and opportunities.</p>
        <Form.Group className="text-center">
          <Button onClick={() => navigate('/auth')} className="cta-button">
            Sign Up / Login
          </Button>
        </Form.Group>
      </section>
      <Footer/>
    </Container>
  );
}

export default LandingPage;
