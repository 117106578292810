import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainHome from './Client/ClientHome/MainHome.js';
import SignUpLogin from './auth/SignUpLogin';
import SignUp from './auth/SignUp';
import DetermineSignUp from './auth/DetermineSignUp';
import TeacherSignUp from './Provider/ProviderSignUp/TeacherSignUp.js';
import Inviting from './Client/ClientHome/Inviting.js';
import Term from './Client/postingProcess/Term.js';
import Title from './Client/postingProcess/Title.js';
import Skills from './Client/postingProcess/Skills.js';
import Scope from './Client/postingProcess/Scope.js';
import Experience from './Client/postingProcess/Experience.js';
import Pudget from './Client/postingProcess/Pudget.js';
import Description from './Client/postingProcess/Description.js';
import Overview from './Client/postingProcess/Overview.js';
import TypeOfWork from './Provider/ProviderSignUp/TypeOfWork.js';
import Picture from './Provider/ProviderSignUp/Picture.js';
import Letter from './Provider/ProviderSignUp/Letter.js';
import Certifications from './Provider/ProviderSignUp/Certifications.js';
import PreviousExperience from './Provider/ProviderSignUp/PreviousExperience.js';
import RatePerHour from './Provider/ProviderSignUp/RatePerHour.js';


import PMainHome from './Provider/ProviderHome/PMainHome.js';
import ClientNotifications from './Client/ClientNotifications/ClientNotifications.js';
import ProposalAtClient from './Client/ProposalAtClient/ProposalAtClient.js';
import ProviderProposal from './auth/ProviderProposal/ProviderProposal.js';
import Accept from './Client/Accept/Accept.js';
import ClientMessages from './Client/ClientMessages/ClientMessages.js';
import Paypal from './Payment/Paypal/Paypal.js';
import Payment from './Payment/Payment.js'
import ChooseMethod from './Payment/ChooseMethod.js';
import Cancel from './auth/Cancel.js';
import ProviderMessages from './Provider/ProviderMessages.js/ProviderMessages.js';
import ProviderToClientMessage from './Provider/ProviderMessages.js/ProviderToClientMessage.js';
import ReceivedMessages1Client from './Client/ReceivedMessagesClient/ReceivedMessages1Client.js';
import ReceivedMessages2Client from './Client/ReceivedMessagesClient/ReceivedMessages2Client.js';
import PaymentRegister from './Provider/ProviderSignUp/PaymentRegister.js';
import PaymentSuccess from './Payment/Paypal/PaymentSuccess.js';
import PaymentCancel from './Payment/Paypal/PaymentCancel.js';
import { useEffect } from 'react';
import ProviderProfile4Provider from './Provider/ProviderProfile4Provider/ProviderProfile4Provider.js';
import ProviderProfile from './Client/providerProfile/ProviderProfile.js';
import ClientPosts from './Client/ClientPosts/ClientPosts.js';
import ProviderInvitaions from './Provider/ProviderInvitaions/ProviderInvitaions.js';
import ProviderInvitation2 from './Provider/ProviderInvitaions/ProviderInvitation2.js';
import MakeReport from './Client/MakeReport/MakeReport.js';
import Blog from './Provider/ProviderSignUp/Blog.js';
import ClientSignUp from './Client/ClientSignUp/ClientSignUp.js';
import ClientProfilePicture from './Client/ClientSignUp/ClientProfilePicture.js';
import ClientCategory from './Client/ClientSignUp/ClientCategory.js';
import ProviderReports from './Provider/ProviderReports/ProviderReports.js';
import CertainProvider from './Client/CertainProvider/CertainProvider.js';
import InvitationAcceptance from './Client/InvitationAcceptance/InvitationAcceptance.js';
import DefinigPage from './DefiningFolder/DefinigPage.js';

import LandingPage from './DefiningFolder/LandingPage.js';
import LearnMorePage from './LearnMore/LearnMorePage.js';
import TestimonialsPage from './Testmonials/TestimonialsPage.js';
import AboutUsPage from './AboutUs/AboutUsPage.js';
import BlogsPage from './Blogs/BlogsPage.js';
import BlogPost from './Blogs/BlogPost.js';
import ContactUs from './contactUs/ContactUs.js';
import ServiceConnect from './Blogs/ServiceConnect.js';
import AffiliateMarketingPost from './Blogs/AffiliateMarketingPost.js';
import NotFoundPage from './NotFoundPage/NotFoundPage.js';
import BeautyFashionBlog from './Blogs/BeautyFashionBlog.js';
import BlogPost5 from './Blogs/BlogPost5.js';
import FindBestCodingTutor from './Blogs/FindBestCodingTutor.js';
import BenefitsOfOnlineLegalConsultations from './Blogs/BenefitsOfOnlineLegalConsultations.js';
import TipsForLearningGraphicDesign from './Blogs/TipsForLearningGraphicDesign.js';
import FindExpertPost from './Blogs1/FindExpertPost.js';
import HireGraphicDesignerPost from './Blogs1/HireGraphicDesignerPost.js';
import SeoOptimizationPost from './Blogs1/SeoOptimizationPost.js';
import VirtualAssistantPost from './Blogs1/VirtualAssistantPost.js';
import PerfectJobPost from './Blogs2/PerfectJobPost.js';
import HiringConsultant from './Blogs2/HiringConsultant.js';
import HighDemandSkills2024 from './Blogs2/HighDemandSkills2024.js';
import FutureOfRemoteWork from './Blogs2/FutureOfRemoteWork.js';
import FreelanceCareerTips from './Blogs2/FreelanceCareerTips.js';
import SEOTrainingGuide from './Blogs3/SEOTrainingGuide.js';
import SeoAuditArticle from './Blogs3/SeoAuditArticle.js';
import SEOArticle from './Blogs3/SEOArticle.js';
import LocalSEOGuide from './Blogs3/LocalSEOGuide.js';
import FreelanceConsultingArticle from './Blogs3/FreelanceConsultingArticle.js';
import ProductivityArticle from './blogs4/ProductivityArticle.js';
import FallFashionTrends from './blogs4/FallFashionTrends.js';
import SellGiftCardsBlogPost from './blogs4/SellGiftCardsBlogPost.js'
import YouTubeAutomationArticle from './blogs4/YouTubeAutomationArticle.js';
import PersonalWebsiteGuide from './blogs4/PersonalWebsiteGuide.js'
import FirebaseFastAPIArticle from './blogs5/FirebaseFastAPIArticle.js'
import MainCourse from './courses/MainCourses.js';
import CryptoCourse from './courses/cryptoCourse/CryptoCourse.js';
import TradingCourse from './courses/tradingCourse/TradingCourse.js';
import SocialMarketingCourse from './courses/socailMarketingCourse/SocialMarketingCourse.js';
import Module1HomePage from './courses/cryptoCourse/module1/Module1HomePage.js';
import RelatedTeachers from './courses/tradingCourse/TradingCourse.js';
import CryptoDetails from './courses/cryptoCourse/CryptoDetails.js';
import TradingDetails from './courses/tradingCourse/TradingDetails.js';
import SocialMarketingCourseDetails from './courses/socailMarketingCourse/SocialMarketingCourseDetails.js';
import Video1 from './courses/cryptoCourse/module1/M1Videos/Video1.js';



function App() {
  let authpermission;
  useEffect(() => {
    authpermission = localStorage.getItem('authenticated')
  }, [authpermission])
    return (
    
     <Routes>
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/auth" element={<SignUpLogin />} />
      
  
      <Route path="/client" element={<MainHome/>} />

      
      {
        console.log('auth from inside => '+   authpermission )
      }
      
        <Route path="/client-sign-up" element={<ClientSignUp />} />

      <Route path="/provider" element={<PMainHome/>} />
      <Route path="/provider-sign-up" element={<TeacherSignUp />} />
      <Route path="/sign-up-client-provider" element={<DetermineSignUp />} />
      <Route path="/about-us" element={<AboutUsPage/>}/>
      <Route path="/digital-marketing" element={<BlogPost/>}/>
      
      {
       <Route path="*" Component={NotFoundPage} /> 
      }
    <Route path="/" element={<DefinigPage/>}/>
    <Route path="/join-us" element={<LandingPage/>}/>
    <Route path="/learn-more" element={<LearnMorePage/>}/>
    <Route path="/testimonials" element={<TestimonialsPage/>}/>
      <Route path="/inviting" element={<Inviting/>}/>
      <Route path="/posting-term" element={<Term/>}/>
      <Route path="/posting-title" element={<Title/>}/>
      <Route path="/posting-skills" element={<Skills/>}/>
      <Route path="/posting-scope" element={<Scope/>}/>
      <Route path="/posting-experience" element={<Experience/>}/>
      <Route path="/posting-pudget" element={<Pudget/>}/>
      <Route path="/posting-description" element={<Description/>}/>
      <Route path="/posting-overview" element={<Overview/>}/>
      <Route path="/type-of-work" element={<TypeOfWork/>}/>
      <Route path="/profile-picture" element={<Picture/>}/>
      <Route path="/letter" element={<Letter/>}/>
      <Route path="/certifications" element={<Certifications/>}/>
      <Route path="/previous-experience" element={<PreviousExperience/>}/>
      <Route path="/rate-per-hour" element={<RatePerHour/>}/>

      <Route path="/blog" element={<Blog/>}/>
      <Route path="/provider-profile-4client" element={<ProviderProfile/>}/>
      <Route path="/notifications" element={<ClientNotifications/>}/>
      <Route path="/proposal" element={<ProposalAtClient/>}/>
      <Route path="/make-proposal" element={<ProviderProposal/>}/>
      <Route path="/accept" element={<Accept/>}/>
      <Route path="/client-messages" element={<ClientMessages/>}/>
      <Route path="/choose-method" element={<ChooseMethod/>}/>
      <Route path="/Stripe" element={<Payment/>}/>
      <Route path="/paypal" element={<Paypal/>}/>
      <Route path="/cancel" element={<Cancel/>}/>
      <Route path="/provider-messages" element={<ProviderMessages/>}/>
      <Route path="/provider-client-messaging" element={<ProviderToClientMessage/>}/>
      <Route path="/client-received-messages" element={<ReceivedMessages1Client/>}/>
      <Route path="/client-access-messages" element={<ReceivedMessages2Client/>}/>
      <Route path="/pay/success" element ={<PaymentSuccess/>}/>
      <Route path="/pay/cancel" element={<PaymentCancel/>}/>
      <Route path="/client-profile-picture" element={<ClientProfilePicture/>}/>
      <Route path="/provider-profile-4provider" element={<ProviderProfile4Provider/>}/>
      <Route path="/client-posts" element= {<ClientPosts/>}/>
      <Route path="/provider-invitaions" element={<ProviderInvitaions/>}/>
      <Route path="/choosen-invitation" element={<ProviderInvitation2/>}/>
      <Route path="/report" element={<MakeReport/>}/>
      <Route path="/client-category" element={<ClientCategory/>}/>
      <Route path="/payment-register" element={<PaymentRegister/>}/>
      <Route path="/my-reports" element={<ProviderReports/>}/>
      <Route path="/certain-provider" element={<CertainProvider/>}/>
      <Route path="/invitation-acceptance" element={<InvitationAcceptance/>}/>
      <Route path="/contact-us" element={<ContactUs/>}/>
      <Route path="/services" element={<ServiceConnect/>}/>
      <Route path="/affiliate-marketing" element={<AffiliateMarketingPost/>}/>
      <Route path="/your-beauty-guidance" element={<BeautyFashionBlog/>}/>
      <Route path="/connect-clients-with-providers" element={<BlogPost5/>}/>
      <Route path="/find-best-coding-tutor-online" element={<FindBestCodingTutor/>}/>
      <Route path="/benefits-of-online-legal-consultations" element={<BenefitsOfOnlineLegalConsultations/>}/>
      <Route path="/tips-for-learning-graphic-design-remotely" element={<TipsForLearningGraphicDesign/>}/>
      <Route path="/find-expert" element={<FindExpertPost/>}/>
      <Route path="/hire-graphic-designer" element={<HireGraphicDesignerPost/>}/>
      <Route path="/seo-optimization" element={<SeoOptimizationPost/>}/>
      <Route path="/virtual-assistance" element={<VirtualAssistantPost/>}/>
      <Route path="/future-of-remote-work" element={<FutureOfRemoteWork/>}/>
      <Route path="/high-demand-skills-2024" element={<HighDemandSkills2024/>}/>
      <Route path="/job-post" element={<PerfectJobPost/>}/>
      <Route path="/freelance-career" element={<FreelanceCareerTips/>}/>
      <Route path="/hiring-consultant" element={<HiringConsultant/>}/>
      <Route path="/seo-training" element={<SEOTrainingGuide/>}/>
      <Route path="/seo-audit" element={<SeoAuditArticle/>}/>
      <Route path="/blogs" element={<SEOArticle/>}/>
      <Route path="/fashion-trends" element={<FallFashionTrends/>}/>


      <Route path="/seo-tools" element={<SEOTrainingGuide/>}/>
      <Route path="/local-seo-strategies" element={<LocalSEOGuide/>}/>
      <Route path="/freelancing-consultant" element={<FreelanceConsultingArticle/>}/>
      <Route path="/maximize-productivity" element={<ProductivityArticle/>}/>
      <Route path="/sell-gift-card" element={<SellGiftCardsBlogPost/>}/>
      <Route path="/youtube-automation" element={<YouTubeAutomationArticle/>}/>
      <Route path="/personal-website" element={<PersonalWebsiteGuide/>}/>
      <Route path="/firebase-fast-api" element={<FirebaseFastAPIArticle/>}/>
      <Route path="/courses" element={<MainCourse/>}/>
      <Route path="/crypto" element={<CryptoCourse/>}/>
      <Route path="/trading" element={<TradingCourse/>}/>
      <Route path="/marketing" element={<SocialMarketingCourse/>}/>
      <Route path="/crypto-course/module-1" element={<Module1HomePage/>}/>
      <Route path="/related-providers" element={<RelatedTeachers/>}/>
      <Route path="/course-details/crypto" element={<CryptoDetails/>}/>
      <Route path="/course-details/trading" element={<TradingDetails/>}/>
      <Route path="/course-details/marketing" element={<SocialMarketingCourseDetails/>}/>
      <Route path="/crypto-course/module-1/video-1" element={<Video1/>}/>
    </Routes>


  );
}

export default App;
