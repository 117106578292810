import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Module1HomePage.css'; // Custom CSS for additional styling

function Module1HomePage() {
  const navigate = useNavigate();

  // Array of video titles (you can replace these with actual video titles)
  const videos = [
    { title: 'Introduction to Crypto', id: 1 },
    { title: 'Understanding Blockchain', id: 2 },
    { title: 'Crypto Wallets Explained', id: 3 },
    { title: 'Security in Cryptocurrency', id: 4 }
  ];

  // Navigate to a video detail page when a button is clicked
  const goToVideo = (id) => {
    navigate(`/crypto-course/module-1/video-${id}`);
  };

  return (
    <Container fluid className="module1-home-container text-center">
      <h1 className="page-title mb-4">Crypto Module One Videos</h1>
      <Row className="justify-content-center">
        {videos.map((video) => (
          <Col key={video.id} xs={12} md={6} lg={6} className="mb-3">
            <Card className="video-card">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="video-title">{video.title}</span>
                <Button
                  variant="primary"
                  className="video-btn"
                  onClick={() => goToVideo(video.id)}
                >
                  Watch
                </Button>
              </Card.Header>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Module1HomePage;
