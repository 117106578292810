import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Buffer } from 'buffer';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProviderId } from '../../reducers/reducers';
import { FaArrowLeft } from 'react-icons/fa';
import Footer from '../../Footer/Footer';


function RelatedTeachers() {
    const [relatedProviders, setRelatedProviders] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchRelatedProviders = async (retryCount = 0) => {
            try {
                const response = await axios.get('https://sell-skill.com/api/endPoints/getRelatedProviders');
                setRelatedProviders(response.data);
            } catch (error) {
                if (retryCount < 3) {
                    setTimeout(() => fetchRelatedProviders(retryCount + 1), 3000);
                } else {
                    console.error('Error fetching related providers:', error);
                }
            }
        };
        fetchRelatedProviders();
    }, []);

    const goToCertainProvider = (id) => {
        dispatch(setProviderId(id));
        navigate('/certain-provider');
    };

    return (
        <RelatedContainer>
            <BackArrow onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </BackArrow>
            <Header>Related Providers in Your Field</Header>
            {relatedProviders.length > 0 ? (
                relatedProviders.map((relatedProvider) => (
                    <div key={relatedProvider._id}>
                        <StyledButton onClick={() => goToCertainProvider(relatedProvider._id)}>
                            <ProviderCard>
                                <Card.Body className="d-flex flex-column align-items-center text-center">
                                    <ProfileImage src={
                                        relatedProvider.picture && relatedProvider.picture.picture && relatedProvider.picture.picture.data
                                            ? 'data:image/jpg;base64,' + Buffer.from(relatedProvider.picture.picture.data, 'binary').toString('base64')
                                            : "/images/NormalProfile.jpg"
                                    } />
                                    <CardTitle>{relatedProvider.name}</CardTitle>
                                    <CardText>{relatedProvider.email}</CardText>
                                    <CardText>{relatedProvider.ratePerHour} / hour</CardText>
                                    <CardText>{relatedProvider.categories.join(', ')}</CardText>
                                </Card.Body>
                            </ProviderCard>
                        </StyledButton>
                        <InviteButton onClick={() => navigate('/inviting')}>
                            <b>Invite</b>
                        </InviteButton>
                    </div>
                ))
            ) : (
                <LoadingMessage>Loading...</LoadingMessage>
            )}
            {/* Feature Sections */}
            <FeaturesSection>
                <Feature>
                    <h4>Trusted Providers</h4>
                    <p>Our platform ensures only the best, highly-rated providers are here to assist you.</p>
                </Feature>
                <Feature>
                    <h4>Secure Payments</h4>
                    <p>Rest assured that your payments are protected with industry-standard security measures.</p>
                </Feature>
                <Feature>
                    <h4>Money-Back Guarantee</h4>
                    <p>We offer a satisfaction guarantee on all services. Not happy? Get your money back.</p>
                </Feature>
            </FeaturesSection>
            <Footer/>
        </RelatedContainer>
    );
}

export default RelatedTeachers;

// Styled Components
const RelatedContainer = styled.div`
    background-color: #002f6c;
    padding: 40px 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const BackArrow = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
        color: #d1ecf1;
    }
`;

const Header = styled.h3`
    color: white;
    margin-bottom: 30px;
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
`;

const StyledButton = styled.div`
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
`;

const ProviderCard = styled(Card)`
    background-color: #1e1e1e;
    color: #d1ecf1;
    padding: 20px;
    border-radius: 15px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 26px 30px -10px rgba(0, 0, 0, 0.69), 
                0 16px 10px -10px rgba(0, 0, 0, 0.73);
    transition: transform 300ms;

    &:hover {
        transform: scale(1.05);
    }
`;

const ProfileImage = styled.img`
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    object-fit: cover;
    border: 4px solid transparent;
    transition: border 300ms;

    &:hover {
        border: 4px solid rgba(249, 249, 249, 0.8);
    }
`;

const CardTitle = styled(Card.Title)`
    color: #d1ecf1;
    font-size: 1.5rem;
    font-weight: bold;
`;

const CardText = styled(Card.Text)`
    color: #d1ecf1;
    font-size: 1rem;
`;

const InviteButton = styled.div`
    background-color: #32CD32;
    height: 40px;
    width: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    color: white;
    font-weight: bold;
    transition: background-color 300ms;

    &:hover {
        background-color: #28a745;
    }
`;

const LoadingMessage = styled.div`
    color: white;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 20px;
`;

const FeaturesSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 30px;
`;

const Feature = styled.div`
    background-color: #1e1e1e;
    color: white;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    text-align: center;
    box-shadow: 0 26px 30px -10px rgba(0, 0, 0, 0.69), 
                0 16px 10px -10px rgba(0, 0, 0, 0.73);

    h4 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    p {
        font-size: 1.1rem;
    }
`;
