import React from 'react';
import { Link } from 'react-router-dom';

function CryptoDetails() {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="card shadow-lg">
            <div className="card-header bg-dark text-white text-center">
              <h2>Cryptocurrency Mastery Course</h2>
            </div>
            <div className="card-body p-4">
              <p className="lead">
                Welcome to the Cryptocurrency Mastery Course, where you will learn everything from the fundamentals of blockchain technology to advanced cryptocurrency trading strategies. Whether you're a beginner or an experienced trader, this course is designed to provide a comprehensive understanding of how cryptocurrencies work, how to invest in them, and how to make the most out of the booming digital asset market.
              </p>
              <h4>What You'll Learn:</h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">Introduction to Blockchain Technology</li>
                <li className="list-group-item">How Bitcoin and Altcoins Work</li>
                <li className="list-group-item">Wallets, Exchanges, and Security Measures</li>
                <li className="list-group-item">Cryptocurrency Investment Strategies</li>
                <li className="list-group-item">Day Trading vs. Long-Term Holding</li>
                <li className="list-group-item">DeFi (Decentralized Finance) and NFTs</li>
              </ul>
              <p>
                By the end of this course, you will be able to confidently trade, invest, and manage cryptocurrencies like a pro. You'll also gain insights into the future of finance and how digital currencies are shaping global markets.
              </p>
              <p>
                This course is perfect for aspiring cryptocurrency investors, traders looking to sharpen their skills, and anyone curious about how blockchain and digital assets are revolutionizing industries.
              </p>
            </div>
            <div className="card-footer text-center">
              <Link to="/crypto" className="btn btn-primary btn-lg">
                Go to Course
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CryptoDetails;
