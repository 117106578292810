import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css'; // Custom styling
import Footer from '../../Footer/Footer';
import ImgSlider from './ImgSlider';
import TopBar from './TopBar';
import { useNavigate } from 'react-router-dom';
const MainHome = () => {
  const navigate = useNavigate()
  return (
    <Container fluid className="p-0">
      {/* TopBar and Slider */}
      <TopBar />
      <ImgSlider />

      {/* Hero Section */}
      <section className="hero-section text-center bg-dark text-light py-5">
        <Container>
          <h1 className="display-4">Learn New Skills, Anytime, Anywhere</h1>
          <p className="lead">Find expert-led courses or book an appointment to discuss your learning needs with top providers.</p>
          <div className="d-flex justify-content-center">
            <Button variant="primary" size="lg" className="m-2 rounded-pill px-4" onClick={() => navigate('/courses')}>
              Browse Courses
            </Button>
            <Button variant="outline-light" size="lg" className="m-2 rounded-pill px-4" onClick={() => navigate('/posting-term')}>
              Make a post
            </Button>
          </div>
        </Container>
      </section>

      {/* Featured Courses Section */}
      <section className="featured-courses py-5">
        <Container>
          <h2 className="text-center mb-4">Popular Courses</h2>
          <Row>
            <Col md={4}>
              <Card className="course-card h-100 shadow-sm">
                <Card.Img variant="top" src="/PopularCourses/CryptoCourse.jpg" alt="Course 1" />
                <Card.Body>
                  <Card.Title>Cryptocurrency Masterclass</Card.Title>
                  <Card.Text>Master the fundamentals of cryptocurrency in this comprehensive course.</Card.Text>
                  <Link to="/course-details/crypto" className="btn btn-primary w-100 rounded-pill">View Details</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="course-card h-100 shadow-sm">
                <Card.Img variant="top" src="/PopularCourses/TradingCourse.jpg" alt="Course 2" />
                <Card.Body>
                  <Card.Title>Trading for Beginners</Card.Title>
                  <Card.Text>Learn the basics of trading in this step-by-step beginner's guide.</Card.Text>
                  <Link to="/course-details/trading" className="btn btn-primary w-100 rounded-pill">View Details</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="course-card h-100 shadow-sm">
                <Card.Img variant="top" src="/PopularCourses/SocialCourse.jpg" alt="Course 3" />
                <Card.Body>
                  <Card.Title>Social Media Marketing</Card.Title>
                  <Card.Text>Boost your business using advanced social media marketing strategies.</Card.Text>
                  <Link to="/course-details/marketing" className="btn btn-primary w-100 rounded-pill">View Details</Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4">How It Works</h2>
          <Row className="text-center">
            <Col md={4}>
              <img src="/howItWorks/BenTenLogo.png" alt="Browse Courses" className="icon mb-3" />
              <h5>Browse Courses</h5>
              <p>Explore and enroll in expert-led courses.</p>
            </Col>
            <Col md={4}>
              <img src="/howItWorks/BenTenLogo.png" alt="Post Request" className="icon mb-3" />
              <h5>Post Requests</h5>
              <p>Request help and get responses from providers.</p>
            </Col>
            <Col md={4}>
              <img src="/howItWorks/BenTenLogo.png" alt="Book Appointment" className="icon mb-3" />
              <h5>Book Appointments</h5>
              <p>Discuss topics with providers via appointments.</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials py-5">
        <Container>
          <h2 className="text-center mb-4">What Our Clients Say</h2>
          <Row>
  <Col md={4}>
    <Card className="testimonial-card p-3 shadow-sm">
      <Card.Img variant="top" src="/personsInTheClientHome/CarterDavid.jpg" alt="John Doe" />
      <Card.Body>
        <Card.Text>"SellSkill helped me learn cryptocurrency trading from scratch!"</Card.Text>
        <Card.Footer className="text-muted">
          <strong>Carter David</strong> - A 35-year-old financial advisor from New York, Carteer was looking to expand his skill set in the growing field of cryptocurrency. With a busy work schedule, he needed a flexible platform where he could learn at his own pace. SellSkill offered just that, enabling him to dive into the world of cryptocurrency trading without compromising his daily routine.
        </Card.Footer>
      </Card.Body>
    </Card>
  </Col>

  <Col md={4}>
    <Card className="testimonial-card p-3 shadow-sm">
      <Card.Img variant="top" src="/personsInTheClientHome/JefferyBones.jpg" alt="Sarah Lee" />
      <Card.Body>
        <Card.Text>"The platform is intuitive, and I can book appointments with experts easily."</Card.Text>
        <Card.Footer className="text-muted">
          <strong>Jeffry Bones</strong> - A 32-year-old marketing consultant from London, Jeffery was looking for quick answers to specific questions about social media strategies. SellSkill's appointment system allowed her to connect with marketing experts at her convenience, giving her the professional advice she needed to fine-tune her campaigns and boost her clients’ performance.
        </Card.Footer>
      </Card.Body>
    </Card>
  </Col>

  <Col md={4}>
    <Card className="testimonial-card p-3 shadow-sm">
      <Card.Img variant="top" src="/personsInTheClientHome/danielJones.jpg" alt="Ahmed Ali" />
      <Card.Body>
        <Card.Text>"I found amazing courses that fit my schedule perfectly!"</Card.Text>
        <Card.Footer className="text-muted">
          <strong>Daniel Jones</strong> - A 30-year-old entrepreneur from Deblin, Daniel needed a platform that provided flexibility, as he juggled managing his business with continuing education. He found SellSkill’s curated courses perfect for his fast-paced life, allowing him to sharpen his skills in business development and digital marketing without disrupting his work commitments.
        </Card.Footer>
      </Card.Body>
    </Card>
  </Col>
</Row>

        </Container>
      </section>

      {/* Appointment Feature */}
      <section className="appointments bg-dark text-light py-5 text-center">
        <Container>
          <h2 className="display-5 mb-4">Talk to an Expert Today</h2>
          <p className="lead">Book an appointment with a provider for expert advice and learning guidance.</p>
          <Button variant="outline-light" size="lg" className="rounded-pill px-4" onClick={() => navigate('/related-providers')}>
            Book Appointment
          </Button>
        </Container>
      </section>

      {/* Footer Section */}
      <Footer />
    </Container>
  );
};

export default MainHome;
