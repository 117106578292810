import React, { useEffect, useState } from 'react';
import { Card, Alert, Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Buffer } from 'buffer';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function TopBar() {
  const [checkNewMessages, setCheckNewMessages] = useState(false);
  const [checkNewInvitationAcceptance, setCheckNewInvitationAcceptance] = useState(false);
  const [newProposals, setNewProposals] = useState(false);
  const [profilePictureSrc, setProfilePictureSrc] = useState('');
  const [name, setName] = useState('');
  const [contentType, setContentType] = useState('undefined');
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => {
    setShowModal(false)
  }
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCheckClientNewMessage = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkClientNewMessage');
      setCheckNewMessages(response.data);
    };
    fetchCheckClientNewMessage();
  }, []);

  useEffect(() => {
    const fetchCheckNewInvitationAcceptance = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getNewInvitationAcceptanceState');
      setCheckNewInvitationAcceptance(response.data);
    };
    fetchCheckNewInvitationAcceptance();
  }, []);

  useEffect(() => {
    const checkNewProposal = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkClientNewProposals');
      setNewProposals(response.data);
    };
    checkNewProposal();
  }, []);

  useEffect(() => {
    fetch('https://sell-skill.com/api/endpoints/getClientProfileData')
      .then(response => response.json())
      .then(result => setName(result.name));
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getClientProfilePicture', {
          responseType: 'arraybuffer',
        });
        const base64 = Buffer.from(response.data, 'binary').toString('base64');
        const mimeType = response.headers['content-type'];
        setContentType(mimeType);
        setProfilePictureSrc(`data:${mimeType};base64,${base64}`);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchImage();
  }, [name]);

  return (
    <TopBarContainer>
      <LeftSection>
        <AlertsContainer>
          {checkNewMessages && <StyledAlert variant="danger">You have new messages!</StyledAlert>}
          {newProposals && <StyledAlert variant="danger">You have a new proposal!</StyledAlert>}
          {checkNewInvitationAcceptance && <StyledAlert variant="danger">Your invitation was accepted!</StyledAlert>}
        </AlertsContainer>
      </LeftSection>
      <MiddleSection>
        <NavLinks>
          <NavLink href="/courses">Our Courses</NavLink>
          <NavLink href="/report">Make Report</NavLink>
          <NavLink href="/notifications">Notifications & Providers</NavLink>
          <NavLink href="/client-received-messages">Messages</NavLink>
          <NavLink href="/client-posts">My Posts</NavLink>
          <NavLink href="/invitation-acceptance">Invitation Acceptance</NavLink>
          <NavLink href="/services">Blogs</NavLink>
          <NavLink href="/about-us">About Us</NavLink>
        </NavLinks>
      </MiddleSection>
      <RightSection>
        <ProfileCard>
          <Card.Body>
            <ProfileDetails>
              <ProfileName>{name}</ProfileName>
              {contentType !== 'undefined' ? (
                <ProfileImage src={profilePictureSrc} />
              ) : (
                <ProfileImage src="/images/NormalProfile.jpg" />
              )}
            </ProfileDetails>
          </Card.Body>
        </ProfileCard>
      </RightSection>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body className="modal-content">
          <h3 className="modal-title">Invest in yourself with sell-skill</h3>
          <p>Check out best sell-skill courses</p>
          <Button variant="primary" className="cta-button" onClick={() => {
            handleClose();
            navigate('/courses');
          }}>
            Get Skill
          </Button>
        </Modal.Body>
      </Modal>
    </TopBarContainer>
  );
}

export default TopBar;

// Styled Components
const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #004085, #000);
  padding: 15px 30px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px 20px;
  }
`;

const LeftSection = styled.div`
  flex: 1;
`;

const MiddleSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const AlertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledAlert = styled(Alert)`
  background-color: #FFA500;
  color: black;
  font-weight: bold;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
`;

const NavLink = styled.a`
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #32CD32;
    color: white;
  }
`;

const ProfileCard = styled(Card)`
  background-color: transparent;
  border: none;
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ProfileName = styled.h5`
  margin-right: 10px;
  color: white;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
`;
