import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import './Video1.css'; // Import the external CSS for styling

function Video1() {
    const [videoUrl, setVideoUrl] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const storage = getStorage();
                const videoRef = ref(storage, 'CryptoCourse/Module1/0918.mp4');
                const url = await getDownloadURL(videoRef);
                setVideoUrl(url);
            } catch (error) {
                console.error('Error fetching video URL: ', error);
            }
        };
        fetchVideoUrl();
    }, []);

    return (
        <div className="video-container">
            <div className="video-header">
                <button className="back-button" onClick={() => navigate('/crypto-course/module-1')}>
                    ← Back
                </button>
                <h1 className="course-title">Cryptocurrency Course: Module 1</h1>
                <p className="course-description">In this module, we will cover the fundamentals of cryptocurrency, blockchain technology, and its applications in modern finance.</p>
            </div>
            <div className="video-content">
                {videoUrl ? (
                    <video className="course-video" controls>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                ) : (
                    <p>Loading video...</p>
                )}
            </div>
        </div>
    );
}

export default Video1;
