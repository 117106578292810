import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function TradingDetails() {
  const navigate = useNavigate();

  const handleGoToCourse = () => {
    navigate('/trading');
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center mb-4">
        <Col md={10}>
          <Card className="shadow-lg border-0">
            <Card.Body className="p-5">
              <h1 className="text-center mb-4">Advanced Trading Strategies Course</h1>
              <Card.Img 
                src="/images/trading-banner.jpg" 
                alt="Trading Course" 
                className="mb-4 img-fluid rounded" 
              />
              <h3>Course Overview</h3>
              <p>
                Master the art of trading with our Advanced Trading Strategies Course. This course is designed for those who
                want to take their trading skills to the next level, providing you with cutting-edge strategies and tools to
                maximize profits and minimize risks in today's volatile markets.
              </p>
              <h4>What You'll Learn</h4>
              <ul>
                <li>Technical Analysis: Chart Patterns, Indicators, and Signals</li>
                <li>Fundamental Analysis: Understanding Market Movers</li>
                <li>Risk Management: Techniques to Protect Your Capital</li>
                <li>How to Develop and Execute a Trading Plan</li>
                <li>Day Trading, Swing Trading, and Scalping Techniques</li>
                <li>Reading Market Sentiment and Psychology</li>
                <li>Automating Trades with Algorithmic Trading</li>
              </ul>
              <h4>Who This Course Is For</h4>
              <p>
                This course is perfect for traders who already have a basic understanding of the markets and want to enhance
                their trading skills. Whether you're looking to start trading professionally or improve your personal trading
                portfolio, this course is for you.
              </p>
              <h4>Course Features</h4>
              <ul>
                <li>Live Market Analysis and Trading Sessions</li>
                <li>Interactive Quizzes and Trading Simulations</li>
                <li>Access to Proprietary Trading Tools and Indicators</li>
                <li>One-on-One Coaching Sessions</li>
                <li>Lifetime Access to Course Materials and Updates</li>
                <li>Certificate of Completion</li>
              </ul>
              <div className="text-center mt-5">
                <Button onClick={handleGoToCourse} variant="primary" size="lg" className="px-4 py-2">
                  Go to Course
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default TradingDetails;
