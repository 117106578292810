import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DefiningPage.css';
import Footer from '../Footer/Footer';

function DefiningPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);

  const services = [
    {
      title: 'Digital Learning Marketplace',
      image: 'images/bestDigitalLearning.png',
      description: 'Explore our top-rated digital learning resources and courses. Buy and sell educational content to enhance your skills.',
      alt: 'Digital Learning Marketplace with Buy and Sell Options',
    },
    {
      title: 'Professional Legal Consulting',
      image: 'images/lawConsultant.jpg',
      description: 'Connect with experienced legal consultants for expert advice on a wide range of legal matters.',
      alt: 'Legal Consulting Services for Expert Advice',
    },
    {
      title: 'Medical Consultation Services',
      image: 'images/medicalConsultation.png',
      description: 'Access professional medical consultations from certified healthcare experts online, anytime, anywhere.',
      alt: 'Online Medical Consultation Services',
    },
    {
      title: 'Digital Marketing Solutions',
      image: 'images/digitalMarketing.png',
      description: 'Promote your educational services effectively with our digital marketing tools and reach a larger audience.',
      alt: 'Effective Digital Marketing for Education Services',
    },
    {
      title: 'Master Programming from Home',
      image: 'images/masterProgramming.png',
      description: 'Discover remote work opportunities and learn programming with our comprehensive online courses.',
      alt: 'Learn Programming and Work from Home',
    },
  ];

  return (
    <>
      {/* SEO Enhancements */}
      <Helmet>
        <title>Sell-Skill: Digital Learning, Legal Consulting, Medical Advice & More</title>
        <meta name="description" content="Sell-Skill offers a platform to buy and sell digital learning resources, access expert legal and medical consultations, and explore digital marketing solutions globally and in the USA." />
        <meta name="keywords" content="digital learning, legal consulting, medical consultation, digital marketing, online courses, programming courses, remote work, USA digital services, professional consulting" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Sell-Skill: Your Go-To Platform for Digital Learning and Professional Consulting" />
        <meta property="og:description" content="Join Sell-Skill to access top digital learning courses, legal and medical consultations, and expert marketing solutions, targeting global users and USA professionals." />
        <meta property="og:image" content="images/hero-image.jpg" /> {/* Replace with an actual hero image URL */}
        <meta property="og:url" content="https://www.sell-skill.com" /> {/* Replace with your actual site URL */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sell-Skill: Digital Learning, Legal Consulting, Medical Advice & More" />
        <meta name="twitter:description" content="Access digital learning resources, legal and medical consultations, and digital marketing solutions with Sell-Skill." />
        <meta name="twitter:image" content="images/hero-image.jpg" />
        <link rel="canonical" href="https://www.sell-skill.com" />
      </Helmet>

      {/* Pop-up Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body className="modal-content">
          <h3 className="modal-title">Join Our Platform Today</h3>
          <p>Sign up now and take the first step towards achieving your professional goals.</p>
          <Button variant="primary" className="cta-button" onClick={() => {
            handleClose();
            navigate('/auth');
          }}>
            Sign Up Now
          </Button>
        </Modal.Body>
      </Modal>

      {/* Main Content */}
      <Container fluid className="py-4" style={{ backgroundColor: '#F5F5F5' }}>
        {/* Hero Section */}
        <section className="hero-section text-center mb-5" style={{ padding: '60px 0', backgroundColor: '#F5F5F5', backgroundImage: 'url(images/header-bg.jpg)', backgroundSize: 'cover', color: '#002f6c' }}>
          <h1 className="hero-title" style={{ fontSize: '36px', fontWeight: 'bold', color: '#002f6c' }}>Empower Your Career with Digital Learning, Legal Consulting, and Remote Opportunities</h1>
          <p className="hero-subtitle" style={{ fontSize: '18px', maxWidth: '800px', margin: '0 auto', color: 'black' }}>
            Elevate your skills and professional growth with access to our comprehensive digital learning marketplace, expert legal consultations, and remote work opportunities. Join professionals worldwide and in the USA who trust Sell-Skill for their career development.
          </p>
          <Button onClick={() => navigate('/auth')} className="cta-button mt-4" style={{ backgroundColor: '#008080', borderColor: '#008080', padding: '10px 20px', fontSize: '18px' }}>
            Get Started - Sign Up / Login
          </Button>
        </section>

        {/* Brand Logos Section */}
        <div className="brand-logos">
          <Container>
            <Row className="text-center">
              <Col><img src="images/brand1-logo.png" alt="Brand 1" className="brand-logo" /></Col>
              <Col><img src="images/brand2-logo.png" alt="Brand 2" className="brand-logo" /></Col>
              <Col><img src="images/brand3-logo.png" alt="Brand 3" className="brand-logo" /></Col>
              <Col><img src="images/brand4-logo.png" alt="Brand 4" className="brand-logo" /></Col>
              <Col><img src="images/brand5-logo.png" alt="Brand 5" className="brand-logo" /></Col>
            </Row>
          </Container>
        </div>

        {/* Services Section */}
        <section className="services-section">
          <h2 className="text-center mb-4" style={{ color: '#002f6c' }}>Explore Our Top Services</h2>
          {services.map((service, index) => (
            <Row key={index} className="align-items-center mb-4 service-row">
              {index % 2 === 0 ? (
                <>
                  <Col xs={12} md={6}>
                    <Card.Img variant="top" src={service.image} className="img-fluid" alt={service.alt} style={{ borderRadius: '10px' }} />
                  </Col>
                  <Col xs={12} md={6}>
                    <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                      <Card.Body className="text-center">
                        <Card.Title style={{ color: '#002f6c', fontSize: '24px' }}>{service.title}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '16px' }}>{service.description}</Card.Text>
                        <Button onClick={() => navigate('/learn-more')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                          Learn More
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={12} md={6} className="order-md-2">
                    <Card.Img variant="top" src={service.image} className="img-fluid" alt={service.alt} style={{ borderRadius: '10px' }} />
                  </Col>
                  <Col xs={12} md={6} className="order-md-1">
                    <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                      <Card.Body className="text-center">
                        <Card.Title style={{ color: '#002f6c', fontSize: '24px' }}>{service.title}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '16px' }}>{service.description}</Card.Text>
                        <Button onClick={() => navigate('/learn-more')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                          Learn More
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
          ))}
        </section>

        {/* Features Section */}
        <div className="features-section">
          <Container>
            <h2 className="section-title text-center">Why Choose Us?</h2>
            <Row>
              <Col md={4}>
                <div className="feature-card">
                  <img src="images/industry-experts.jpg" alt="Industry Experts" className="img-fluid" />
                  <h3 className="feature-title">Learn from Industry Experts</h3>
                  <p className="feature-description">
                    Our consultants and instructors are leaders in their fields with years of experience.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="images/flexible-learning.jpg" alt="Flexible Learning" className="img-fluid" />
                  <h3 className="feature-title">Flexible Learning</h3>
                  <p className="feature-description">
                    Access our resources anytime, anywhere. Learn on your schedule.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="images/career-growth.jpg" alt="Career Growth" className="img-fluid" />
                  <h3 className="feature-title">Career Growth</h3>
                  <p className="feature-description">
                    Whether you're starting out or advancing your career, we have the resources to help you succeed.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Additional Services Section */}
        <section className="additional-services text-center mt-5" style={{ padding: '40px 0', backgroundColor: '#F5F5F5' }}>
          <h2 style={{ color: '#002f6c' }}>Explore More Services</h2>
          <p className="mb-4" style={{ color: '#333', fontSize: '16px', maxWidth: '800px', margin: '0 auto' }}>
            We offer a wide range of services across different fields to meet your needs.
          </p>
          <Row>
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="images/professional-marketing.jpg" className="img-fluid" alt="Professional Marketing Services" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Professional Marketing Services</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                    Access expert marketing professionals to help you grow your brand and reach a wider audience.
                  </Card.Text>
                  <Button onClick={() => navigate('/learn-more')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="images/business-strategy.jpg" className="img-fluid" alt="Business Strategy Consulting" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Business Strategy Consulting</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                    Work with top business strategists to develop a plan that will take your business to the next level.
                  </Card.Text>
                  <Button onClick={() => navigate('/learn-more')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="images/financial-planning.jpg" className="img-fluid" alt="Financial Planning Services" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Financial Planning Services</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                    Get expert financial advice to help you manage your finances and plan for the future.
                  </Card.Text>
                  <Button onClick={() => navigate('/learn-more')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Featured Professionals Section */}
        <div className="featured-professionals-section">
          <Container>
            <h2 className="section-title text-center">Meet Our Top Professionals</h2>
            <Row>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="images/marketing-expert.jpg" />
                  <Card.Body>
                    <Card.Title>John Doe - Marketing Guru</Card.Title>
                    <Card.Text>
                      With over 15 years of experience, John has helped hundreds of businesses grow their online presence.
                    </Card.Text>
                    <Button variant="outline-primary" className="cta-button">Book a Session</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="images/design-expert.jpg" />
                  <Card.Body>
                    <Card.Title>Jane Smith - Design Pro</Card.Title>
                    <Card.Text>
                      An award-winning designer, Jane brings creativity and innovation to every project she undertakes.
                    </Card.Text>
                    <Button variant="outline-primary" className="cta-button">Book a Session</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="images/tech-expert.jpg" />
                  <Card.Body>
                    <Card.Title>David Lee - Tech Specialist</Card.Title>
                    <Card.Text>
                      David is a tech wizard, specializing in developing innovative solutions for businesses of all sizes.
                    </Card.Text>
                    <Button variant="outline-primary" className="cta-button">Book a Session</Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Popular Courses Section */}
        <div className="courses-section">
          <Container>
            <h2 className="section-title text-center">Our Popular Courses</h2>
            <Row>
              <Col md={3}>
                <div className="course-card">
                  <img src="images/course1.jpg" alt="Course 1" className="img-fluid" />
                  <h4 className="course-title">Digital Marketing Mastery</h4>
                </div>
              </Col>
              <Col md={3}>
                <div className="course-card">
                  <img src="images/course2.jpg" alt="Course 2" className="img-fluid" />
                  <h4 className="course-title">Advanced Graphic Design</h4>
                </div>
              </Col>
              <Col md={3}>
                <div className="course-card">
                  <img src="images/course3.jpg" alt="Course 3" className="img-fluid" />
                  <h4 className="course-title">Project Management Professional</h4>
                </div>
              </Col>
              <Col md={3}>
                <div className="course-card">
                  <img src="images/course4.jpg" alt="Course 4" className="img-fluid" />
                  <h4 className="course-title">Data Science & Analytics</h4>
                </div>
              </Col>
            </Row>
            <div className="text-center mt-4">
              <Button variant="primary" className="cta-button">Explore All Courses</Button>
            </div>
          </Container>
        </div>

        {/* Success Stories Section */}
        <div className="success-stories-section">
          <Container>
            <h2 className="section-title text-center">Success Stories</h2>
            <Row>
              <Col md={6}>
                <img src="images/success-story1.jpg" alt="Success Story 1" className="img-fluid" />
                <h4 className="success-title">Adam's Journey to Marketing Success</h4>
                <p className="success-description">
                  After enrolling in our Digital Marketing Mastery course, Emily grew her business by 300% within six months.
                </p>
              </Col>
              <Col md={6}>
                <img src="images/success-story2.jpg" alt="Success Story 2" className="img-fluid" />
                <h4 className="success-title">Michael's Design Career Transformation</h4>
                <p className="success-description">
                  Michael transitioned from a beginner to an award-winning designer with our Advanced Graphic Design course.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Call to Action Section */}
        <div className="call-to-action-section">
          <Container>
            <h2 className="cta-title">Ready to Start Your Journey?</h2>
            <p className="cta-subtitle">Join thousands of professionals who have transformed their careers with our courses and consulting services.</p>
            <Button variant="light" className="cta-button" onClick={() => navigate('/auth')}>Get Started Now</Button>
          </Container>
        </div>
        
        {Footer && <Footer />}
      </Container>
    </>
  );
}

export default DefiningPage;
