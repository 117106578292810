import React from 'react';
import { Container, Row, Col, Card, Image, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AboutUsPage.css';
import Footer from '../Footer/Footer';

function AboutUsPage() {
  return (
    <>
      <Helmet>
        <title>About Sell-Skill - Empowering Skills & Opportunities</title>
        <meta
          name="description"
          content="Learn about Sell-Skill, a platform dedicated to bridging the gap between knowledge seekers and experts. Explore our mission, values, and meet our team committed to empowering your success."
        />
        <meta
          name="keywords"
          content="Sell-Skill, online learning, skill development, work-from-home, expert guidance, online courses"
        />
      </Helmet>
      <Container fluid className="py-5 about-us-page" style={styles.container}>
        <section className="hero-section text-center mb-5">
          <h1 className="page-title" style={styles.pageTitle}>About Sell-Skill</h1>
          <p className="page-subtitle" style={styles.pageSubtitle}>
            Empowering Individuals with Knowledge, Skills, and Opportunities
          </p>
          <Image
            src="/images/aboutSellSkill.png"
            alt="About Sell-Skill"
            className="img-fluid mb-4"
            style={styles.heroImage}
          />
        </section>

        <section className="mission-section mb-5">
          <Row className="align-items-center">
            <Col md={6} className="mb-4">
              <Image
                src="/images/ourMission.png"
                alt="Sell-Skill Mission"
                className="img-fluid"
                style={styles.missionImage}
              />
            </Col>
            <Col md={6}>
              <h2 className="mb-4" style={styles.sectionTitle}>Our Mission</h2>
              <p style={styles.sectionText}>
                At Sell-Skill, our mission is to bridge the gap between those who need knowledge and those who have it. We empower individuals and businesses by providing access to expert guidance, high-quality online courses, and legitimate remote work opportunities. Our platform is designed to help you thrive in today’s fast-paced, competitive environment.
              </p>
            </Col>
          </Row>
        </section>

        <section className="values-section mb-5">
          <h2 className="text-center mb-4" style={styles.sectionTitle}>Our Core Values</h2>
          <Row>
            <Col md={4} sm={6} className="mb-4">
              <Card className="text-center values-card h-100" style={styles.valuesCard}>
                <Card.Body>
                  <Card.Title style={styles.cardTitle}>Integrity</Card.Title>
                  <Card.Text style={styles.cardText}>
                    We uphold the highest standards of integrity in all our actions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={6} className="mb-4">
              <Card className="text-center values-card h-100" style={styles.valuesCard}>
                <Card.Body>
                  <Card.Title style={styles.cardTitle}>Excellence</Card.Title>
                  <Card.Text style={styles.cardText}>
                    We strive for excellence in everything we do, from our platform features to our customer service.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={6} className="mb-4">
              <Card className="text-center values-card h-100" style={styles.valuesCard}>
                <Card.Body>
                  <Card.Title style={styles.cardTitle}>Innovation</Card.Title>
                  <Card.Text style={styles.cardText}>
                    We embrace innovation to continuously improve and offer cutting-edge solutions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        <section className="team-section text-center py-5">
          <h2 style={styles.sectionTitle}>Meet Our Team</h2>
          <p style={styles.sectionText}>
            Our team is composed of industry experts, dedicated professionals, and innovative thinkers, all working together to bring you the best platform for knowledge and skill-building.
          </p>
          <Row className="justify-content-center">
            <Col md={3} sm={6} className="mb-4">
              <Card className="team-card h-100" style={styles.teamCard}>
                <Card.Img variant="top" src="/images/aw.jpg" alt="John Doe - CEO & Founder" />
                <Card.Body>
                  <Card.Title style={styles.cardTitle}>Awab Hassan</Card.Title>
                  <Card.Text style={styles.cardText}>CEO & Founder</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={6} className="mb-4">
              <Card className="team-card h-100" style={styles.teamCard}>
                <Card.Img variant="top" src="/images/M2.png" alt="Jane Smith - CTO" />
                <Card.Body>
                  <Card.Title style={styles.cardTitle}>Jeff Smith</Card.Title>
                  <Card.Text style={styles.cardText}>Chief Technology Officer</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={6} className="mb-4">
              <Card className="team-card h-100" style={styles.teamCard}>
                <Card.Img variant="top" src="/images/M1.jpg" alt="Sarah Johnson - Head of Marketing" />
                <Card.Body>
                  <Card.Title style={styles.cardTitle}>Adam Johnson</Card.Title>
                  <Card.Text style={styles.cardText}>Head of Marketing</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        <section className="cta-section text-center py-5" style={styles.ctaSection}>
          <h2 style={styles.sectionTitle}>Join Us on Our Journey</h2>
          <p style={styles.sectionText}>
            Be a part of a platform that values knowledge, innovation, and excellence. Sign up today to start your journey with Sell-Skill.
          </p>
          <Button href="/auth" className="cta-button" style={styles.ctaButton}>
            Sign Up / Login
          </Button>
        </section>

        <Footer />
      </Container>
    </>
  );
}

const styles = {
  container: {
    backgroundColor: '#F5F5F5', // Light Gray background
    color: '#002f6c', // Navy Blue text
  },
  pageTitle: {
    color: '#008080', // Teal
    fontWeight: 'bold',
  },
  pageSubtitle: {
    color: '#002f6c', // Navy Blue
  },
  heroImage: {
    maxHeight: '400px',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 47, 108, 0.2)', // Navy Blue shadow
  },
  missionImage: {
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 47, 108, 0.2)', // Navy Blue shadow
  },
  sectionTitle: {
    color: '#008080', // Teal
    fontWeight: 'bold',
  },
  sectionText: {
    color: '#002f6c', // Navy Blue
  },
  valuesCard: {
    backgroundColor: '#FFFFFF', // White background
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 47, 108, 0.2)', // Navy Blue shadow
  },
  cardTitle: {
    color: '#008080', // Teal
    fontWeight: 'bold',
  },
  cardText: {
    color: '#002f6c', // Navy Blue
  },
  teamCard: {
    backgroundColor: '#FFFFFF', // White background
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 47, 108, 2)', // Navy Blue shadow
  },
  ctaSection: {
    backgroundColor: '#FFFFFF', // White background
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 47, 108, 0.2)', // Navy Blue shadow
  },
  ctaButton: {
    backgroundColor: '#32CD32', // Vibrant Green
    color: '#FFFFFF',
    borderRadius: '30px',
    padding: '10px 20px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
};

export default AboutUsPage;
