import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './CryptoCourse.css'; // Custom CSS for additional styling

function CryptoCourse() {
  const [activeModule, setActiveModule] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  // Function to toggle module descriptions
  const toggleModule = (module) => {
    setActiveModule(activeModule === module ? null : module);
  };

  // Function to navigate to the module route when the card is clicked
  const navigateToModule = (module) => {
    navigate(`/crypto-course/module-${module}`); // Use navigate instead of history.push
  };

  return (
    <Container fluid className="crypto-course-container text-center">
      <h1 className="course-title mb-4">Cryptocurrency Course</h1>
      <Row className="justify-content-center">
        {[1, 2, 3, 4].map((module) => (
          <Col key={module} xs={12} md={6} lg={6} className="mb-3">
            <Card className="module-card">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span
                  className="module-title"
                  onClick={() => navigateToModule(module)}
                  style={{ cursor: 'pointer' }}
                >
                  Module {module}
                </span>
                <Button
                  variant="link"
                  className="module-toggle-btn"
                  onClick={() => toggleModule(module)}
                >
                  {activeModule === module ? '▲' : '▼'}
                </Button>
              </Card.Header>
              {activeModule === module && (
                <Card.Body className="module-description">
                  <p>
                    This is the description for Module {module}. It covers various topics related to cryptocurrency, such as blockchain, crypto wallets, trading, and security.
                  </p>
                </Card.Body>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default CryptoCourse;
