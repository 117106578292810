import React from 'react'

function SocialMarketingCourse() {
  return (
    <div>
      socail marketing
    </div>
  )
}

export default SocialMarketingCourse
