import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

function ClientProfilePicture() {
    const [picture, setPicture] = useState('');
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate("/client-category");
    };

    const handleUpload = async (file) => {
        const formData = new FormData();
        formData.append('picture', file);
        await axios.post('https://sell-skill.com/api/endpoints/insertClientPicture', formData);
        setPicture(file); // Update the picture preview
    };

    return (
        <div style={styles.container}>
            <Container>
                <Card style={styles.outerCard}>
                    <Card.Body style={styles.innerCard}>
                        <Card.Title style={styles.title}>
                            <b>A nice profile picture</b>
                        </Card.Title>
                        <div style={styles.uploadContainer}>
                            <input
                                type="file"
                                name="picture"
                                onChange={(e) => handleUpload(e.target.files[0])}
                                style={styles.fileInput}
                            />
                        </div>
                        {picture && (
                            <img
                                src={URL.createObjectURL(picture)}
                                style={styles.previewImage}
                                alt="Profile Preview"
                            />
                        )}
                        <div style={styles.buttonContainer}>
                            <Button onClick={handleNextClick} style={styles.button}>
                                Next
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

const styles = {
    container: {
        height: '100vh',
        backgroundColor: '#002f6c', // Navy blue background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        boxSizing: 'border-box',
    },
    outerCard: {
        backgroundColor: '#F5F5F5', // Light gray background for the outer card
        color: '#008080', // Teal text color
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '100%',
        maxWidth: '500px',
        margin: '0 auto',
        boxSizing: 'border-box',
    },
    innerCard: {
        backgroundColor: '#FFFFFF', // White background for the inner card
        borderRadius: '15px',
        padding: '20px',
        position: 'relative',
        textAlign: 'center',
    },
    title: {
        fontSize: '24px',
        marginBottom: '20px',
        color: '#002f6c', // Navy blue text color for the title
    },
    uploadContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    fileInput: {
        color: '#333333', // Dark gray text color for the file input
        width: '100%',
        maxWidth: '350px',
        margin: 'auto',
    },
    previewImage: {
        width: '140px',
        height: '140px',
        borderRadius: '70px',
        display: 'block',
        margin: '20px auto',
        border: '2px solid #008080', // Teal border around the preview image
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    button: {
        width: '120px',
        backgroundColor: '#008080', // Teal button color
        borderColor: '#008080',
        color: '#ffffff', // White text color for the button
    },
};

export default ClientProfilePicture;
