import React, { useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function TypeOfWork() {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [others, setOthers] = useState('');

  const handleNextClick = async () => {
    navigate("/client");
    console.log('categories ===> ', category);
    await axios.post('https://sell-skill.com/api/endpoints/clientCategory', category);
  };

  const handleCategoryClick = (cat) => {
    setCategory((prevCategories) => {
      if (prevCategories.includes(cat)) {
        return prevCategories.filter((c) => c !== cat);
      } else {
        return [...prevCategories, cat];
      }
    });
  };

  const handleAddOther = () => {
    if (others.trim() && !category.includes(others)) {
      setCategory((prevCategories) => [...prevCategories, others]);
      setOthers('');
    }
  };

  return (
    <div style={styles.container}>
      <Container>
        <Card style={styles.outerCard}>
          <Card style={styles.innerCard}>
            <Card.Title style={styles.title}>
              <b>Which category are you interested in?</b>
            </Card.Title>
            <div style={styles.buttonsContainer}>
              {['Affiliate marketing', 'Digital marketing', 'Coding', 'AI', 'Medicine', 'Health & fitness', 'Legal & financial advice', 'Languages', 'Hobbies & skills', 'Cryptocurrency', 'Home improvement & DIY', 'Creative services', 'Education & tutoring', 'Entertainment & Leisure', 'Dropshipping', 'Pet services'].map((cat) => (
                <Button
                  key={cat}
                  style={category.includes(cat) ? styles.selectedButton : styles.button}
                  onClick={() => handleCategoryClick(cat)}
                >
                  {cat}
                </Button>
              ))}
            </div>
            <Form.Control
              type="text"
              value={others}
              onChange={(e) => setOthers(e.target.value)}
              placeholder="Enter other field ..."
              style={styles.input}
            />
            <Button style={styles.addButton} onClick={handleAddOther}>Add Other</Button>
            <Button style={styles.nextButton} onClick={handleNextClick}>Next</Button>
          </Card>
        </Card>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    height: '100vh',
    backgroundColor: '#002f6c', // Navy blue background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px', // Padding to avoid edge issues on small screens
  },
  outerCard: {
    backgroundColor: '#F5F5F5', // Light gray background for the outer card
    borderRadius: '20px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  },
  innerCard: {
    backgroundColor: '#FFFFFF', // White background for the inner card
    borderRadius: '20px',
    padding: '20px',
  },
  title: {
    position:'relative',
    top:'10px',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#008080', // Teal color for the title
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center', // Center buttons on smaller screens
    marginBottom: '20px',
  },
  button: {
    width: '150px',
    margin: '5px',
    backgroundColor: '#008080', // Teal button color
    borderColor: '#008080',
    color: '#FFFFFF', // White text color
    '@media (max-width: 768px)': {
      width: '100px', // Adjust width for smaller screens
    },
  },
  selectedButton: {
    width: '150px',
    margin: '5px',
    backgroundColor: '#32CD32', // Vibrant green color for selected buttons
    borderColor: '#008080',
    color: '#FFFFFF',
    '@media (max-width: 768px)': {
      width: '100px', // Adjust width for smaller screens
    },
  },
  input: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '10px',
    borderRadius: '5px',
    width: '100%',
  },
  addButton: {
    width: '100%',
    backgroundColor: '#008080', // Teal button color
    borderColor: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  nextButton: {
    width: '100%',
    backgroundColor: '#002f6c', // Navy blue button color
    borderColor: '#002f6c',
    color: '#FFFFFF',
    borderRadius: '5px',
  },
};

export default TypeOfWork;
