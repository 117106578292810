import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainCourse.css'; // You can add your custom CSS here

function MainCourse() {
  const courses = [
    { id: 1, name: 'Cryptocurrency Course', route: '/crypto', image: 'images/crypto.jpg' },
    { id: 2, name: 'Trading Course', route: '/trading', image: 'images/trading.jpg' },
    { id: 3, name: 'Social Marketing Course', route: '/marketing', image: 'images/marketing.jpg' }
  ];

  return (
    <div className="container-fluid main-course" style={{ backgroundColor: '#001f3f', minHeight: '100vh' }}>
      <h1 className="text-center text-white mb-5">Our Courses</h1>
      <div className="row justify-content-center">
        {courses.map((course) => (
          <div key={course.id} className="col-md-4 mb-4">
            <Link to={course.route} className="course-link">
              <div className="card course-card text-center">
                <img src={course.image} className="card-img-top" alt={course.name} />
                <div className="card-body">
                  <h5 className="card-title">{course.name}</h5>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MainCourse;
